import { Button, CircularProgress, Typography } from "@mui/material";
import Colors from "../../style";

export const PrimaryButton = (props) => {
  return (
    <Button
      sx={{
        fontFamily: "Poppins",
        background: Colors.buttonGradient,
        textTransform: "capitalize",
        color: Colors.black,
        borderRadius: "15px",
        p: "6px 15px",
        whiteSpace: "nowrap",
        ...props.buttonStyle,
      }}
      {...props}
    >
      {props?.loading ?
        <CircularProgress
          sx={{
            color: Colors.white,
            width: "20px !important",
            height: "20px !important"
          }}
        /> :
        <Typography
          sx={{
            fontWeight: 900,
            fontSize: "14px"
          }}
        >
          {props.title}
        </Typography>
      }
    </Button>
  )
};