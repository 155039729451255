import React from 'react';
import { AppBar, Box, CardMedia, Container, Grid2, Toolbar, Typography } from '@mui/material';
import Colors from '../../style';
import Images from '../../assets/images';
import { useNavigate, useLocation } from 'react-router-dom';
function Header() {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  return (
    <AppBar
      position="relative"
      sx={{
        boxShadow: "none",
        zIndex: 1300,
        background: Colors.dark1
      }}
    >
      <Container maxWidth={"xl"}>
        <Toolbar
          sx={{
            py: "12px",
            justifyContent: "space-between"
          }}
        >
          <Box
            sx={{
              width: { md: "266px", sm: "120px", xs: "120px" },
              display: "flex",
            }}
          >
            <Box
              sx={{
                width: { md: "193px", sm: "100%", xs: "100%" },
              }}
            >
              <Box
                component={"a"}
                sx={{
                  cursor: "pointer"
                }}
                onClick={() => navigate("/")}
              >
                <CardMedia
                  component={"img"}
                  src={Images.logo}
                  sx={{
                    width: "100%",
                    objectFit: "contain"
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Grid2
            container
            justifyContent={"flex-end"}
            flexWrap={"nowrap"}
            gap={2}
          >
            <Grid2 size={7}>
              <Box component={"a"}>
                <Typography
                  sx={{
                    fontSize: { md: "16px", sm: "14px", xs: "14px" },
                    fontFamily: "Poppins",
                    color: pathname == "/privacy-policy" ? Colors.primary : Colors.white,
                    cursor: "pointer",
                    ":hover": {
                      color: Colors.primary
                    }
                  }}
                  onClick={() => navigate("/privacy-policy")}
                >
                  Privacy Policy
                </Typography>
              </Box>
            </Grid2>
            <Grid2 size={4.1}>
              <Box component={"a"}>
                <Typography
                  sx={{
                    fontSize: { md: "16px", sm: "14px", xs: "14px" },
                    fontFamily: "Poppins",
                    color: pathname == "/support" ? Colors.primary : Colors.white,
                    cursor: "pointer",
                    ":hover": {
                      color: Colors.primary
                    }
                  }}
                  onClick={() => navigate("/support")}
                >
                  Support
                </Typography>
              </Box>
            </Grid2>
          </Grid2>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Header