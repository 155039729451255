import LandingPage from "../../views/landingPage";
import PrivacyPolicy from "../../views/privacyPolicy";
import Support from "../../views/support";
// import TermsConditions from "../../views/terms&Conditions";

const webRoutes = [
  {
    path: "/",
    component: <LandingPage />
  },
  {
    path: "/privacy-policy",
    component: <PrivacyPolicy />
  },
  {
    path: "/support",
    component: <Support />
  },
  // {
  //   path: "/terms-and-conditions",
  //   component: <TermsConditions />
  // },
]

export default webRoutes;