import { TextField } from "@mui/material";
import styled from "@emotion/styled";
import Colors from "../../style";

const CustomField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderRadius: "15px",
      borderColor: Colors.white, // Remove the default border
    },
    '&:hover fieldset': {
      borderColor: Colors.white, // Remove the border on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: Colors.primary, // Remove the border on focus
      borderWidth: "1px",
      color: Colors.primary
    },
    color: Colors.white,
  },
  '& .MuiInputBase-input': {
    color: Colors.white,
    fontSize: '14px',
  },
}));

export default function InputField({
  register,
  fullWidth,
  label,
  InputLabelProps,
  error,
  helperText,
  rows,
  multiline
}) {
  return (
    <CustomField
      fullWidth={fullWidth}
      label={label}
      slotProps={{ inputLabel: InputLabelProps }}
      {...register}
      error={error}
      helperText={helperText}
      rows={rows}
      multiline={multiline}
    />
  )
}