import React from 'react';
import { Box, Container, Grid2 } from '@mui/material';
import { PrimaryButton } from '../../components/buttons';
import Colors from '../../style';
import Images from '../../assets/images';
import { useForm } from 'react-hook-form';
import { SuccessToaster } from '../../components/toaster';
import InputField from '../../components/inputField';

function Support() {
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const onSubmit = () => {
    SuccessToaster("Submitted Successfully");
    reset();
  }

  return (
    <Box
      sx={{
        backgroundImage: `url(${Images.bannerBackground})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Box
        sx={{
          color: Colors.white,
          p: 3
        }}
      >
        <Grid2 container justifyContent={"center"}>
          <Grid2 size={8}>
            <Box
              sx={{
                background: Colors.dark1,
                py: 3,
                boxShadow: `0px 0px 20px 0px ${Colors.shadowColor + "B3"}`,
                borderRadius: "25px"
              }}
            >
              <Container maxWidth={"xl"}>
                <Grid2
                  container
                  spacing={2}
                  component={"form"}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Grid2 size={{ md: 6, sm: 12, xs: 12 }}>
                    <InputField
                      fullWidth
                      label={"First Name"}
                      register={register("fName", {
                        required: "First Name"
                      })}
                      InputLabelProps={{
                        sx: {
                          color: Colors.white,
                          "&.Mui-focused": {
                            color: Colors.primary,
                          }
                        }
                      }}
                      error={errors?.fName && true}
                      helperText={errors?.fName?.message}
                    />
                  </Grid2>
                  <Grid2 size={{ md: 6, sm: 12, xs: 12 }}>
                    <InputField
                      fullWidth
                      label={"Last Name"}
                      register={register("lName", {
                        required: "Last Name"
                      })}
                      InputLabelProps={{
                        sx: {
                          color: Colors.white,
                          "&.Mui-focused": {
                            color: Colors.primary,
                          }
                        }
                      }}
                      error={errors?.lName && true}
                      helperText={errors?.lName?.message}
                    />
                  </Grid2>
                  <Grid2 size={{ md: 6, sm: 12, xs: 12 }}>
                    <InputField
                      fullWidth
                      register={register("email", {
                        required: "Email"
                      })}
                      label={"Email"}
                      InputLabelProps={{
                        sx: {
                          color: Colors.white,
                          "&.Mui-focused": {
                            color: Colors.primary,
                          }
                        }
                      }}
                      error={errors?.email && true}
                      helperText={errors?.email?.message}
                    />
                  </Grid2>
                  <Grid2 size={{ md: 6, sm: 12, xs: 12 }}>
                    <InputField
                      fullWidth
                      label={"Contact"}
                      register={register("contact", {
                        required: "Contact"
                      })}
                      InputLabelProps={{
                        sx: {
                          color: Colors.white,
                          "&.Mui-focused": {
                            color: Colors.primary,
                          }
                        }
                      }}
                      error={errors?.contact && true}
                      helperText={errors?.contact?.message}
                    />
                  </Grid2>
                  <Grid2 size={{ md: 12, sm: 12, xs: 12 }}>
                    <InputField
                      fullWidth
                      label={"Address"}
                      register={register("address", {
                        required: "Address"
                      })}
                      InputLabelProps={{
                        sx: {
                          color: Colors.white,
                          "&.Mui-focused": {
                            color: Colors.primary,
                          }
                        }
                      }}
                      error={errors?.address && true}
                      helperText={errors?.address?.message}
                    />
                  </Grid2>
                  <Grid2 size={{ md: 12, sm: 12, xs: 12 }}>
                    <InputField
                      fullWidth
                      label={"Message"}
                      register={register("message", {
                        required: "Message"
                      })}
                      InputLabelProps={{
                        sx: {
                          color: Colors.white,
                          "&.Mui-focused": {
                            color: Colors.primary,
                          }
                        }
                      }}
                      multiline={true}
                      rows={6}
                      error={errors?.message && true}
                      helperText={errors?.message?.message}
                    />
                  </Grid2>
                  <Grid2 size={{ md: 12, sm: 12, xs: 12 }}>
                    <PrimaryButton
                      fullWidth
                      type={"submit"}
                      title={"Submit"}
                      buttonStyle={{ py: 1.6 }}
                    />
                  </Grid2>
                </Grid2>
              </Container>
            </Box>
          </Grid2>
        </Grid2>
      </Box>
    </Box>
  )
}

export default Support